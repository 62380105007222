/* jshint esversion:6 */
(function () {
    /* globals console:false */
    var customMetrics;
    var customDimensions;
    var globalProperties;

    // Constraints regarding preventing custom metrics/dimensions from overflowing onto other events requires this.
    var clearedValues = {};

    for (i = 1; i <= 200; i++) {
        clearedValues["metric" + i] = null;
        clearedValues["dimension" + i] = null;
    }

    function elementMatches(el, s) {
        const matches = el.matches || el.msMatchesSelector;
        return matches.call(el, s);
    }

    function closestElement(el, s) {
        if (el.closest) {
            return el.closest(s);
        }

        if (!document.documentElement.contains(el)) {
            return null;
        }

        do {
            if (elementMatches(el, s)) {
                return el;
            }
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    }

    function forEach(arr, cb) {
        if (arr.forEach) {
            return arr.forEach(cb);
        }

        for (var i = 0; i < arr.length; i++) {
            cb(arr[i]);
        }
    }

    function findAttr(el, s) {
        const found = closestElement(el, '[' + s + ']');
        if (found !== null) {
            return found.getAttribute(s);
        }
        return undefined;
    }

    function clearMetricsDimensions() {
        if (typeof ga === 'function') {
            ga('set', clearedValues);
        }
    }

    const EVENT_ATTR = '[data-event-type]';

    window.initDataTags = function(metrics, dimensions, analytics, properties) {
        const attributes = [
            'data-banner-id',
            'data-entity-id',
            'data-event-type',
            'data-list-name',
            'data-name',
            'data-position',
            'data-product-brand',
            'data-product-category',
            'data-product-price',
            'data-product-sku',
            'data-product-variant',
            'data-currency-code'
        ]
            .concat(metrics)
            .concat(dimensions);

        customMetrics = metrics;
        customDimensions = dimensions;
        globalProperties = properties;

        analytics.ready(function () {
            setTimeout(function () {
                const elements = [];
                forEach(document.body.querySelectorAll(EVENT_ATTR), function (el) {
                    // every element in this list needs to trigger an analytics event
                    elements.push(el);
                });

                sendEvents(elements, analytics);

                const observer = new MutationObserver(function (records) {
                    const elements = [];

                    function findEventTargets(el) {
                        if (elementMatches(el, EVENT_ATTR)) {
                            elements.push(el);
                        }

                        forEach(el.querySelectorAll(EVENT_ATTR), function (child) {
                            elements.push(child);
                        });
                    }

                    forEach(records, function (record) {
                        if (record.type === 'childList') {
                            forEach(record.addedNodes, function (node) {
                                if (!(node instanceof Element)) {
                                    return;
                                }
                                findEventTargets(node);
                            });
                        } else if (record.type === 'attributes') {
                            findEventTargets(record.target);
                        }
                    });

                    sendEvents(elements, analytics);
                });

                observer.observe(document.body, {
                    childList: true,
                    attributes: true,
                    subtree: true,
                    attributeFilter: attributes
                });
            }, 100);
        });
    };

    function sendEvents(elements, analytics) {
        function payloadsMatch(newPayload, oldPayload) {
            return buildPayloadString(newPayload) === oldPayload;
        }

        const payloads = {};
        const payloadTable = {
            'list': function (el) {
                const payload = buildProductPayload(el);
                if (payload.variant === '') {
                    payload.variant = undefined;
                }
                if (!payloadsMatch(payload, el.getAttribute('data-analytics-sent'))) {
                    const list = payload.list;
                    delete payload.list;
                    payloads.list = payloads.list || {};
                    payloads.list[list] = payloads.list[list] || [];
                    payloads.list[list].push(payload);
                    payload.list = list;
                    payload.el = el;
                }

                return payload;
            },
            'product': function (el) {
                const payload = buildProductPayload(el);

                customMetrics.forEach(function (customMetric) {
                    payload[customMetric] = findAttr(el, customMetric);
                });

                customDimensions.forEach(function (customDimension) {
                    payload[customDimension] = findAttr(el, customDimension);
                });

                if (!payloadsMatch(payload, el.getAttribute('data-analytics-sent'))) {
                    payloads.product = payloads.product || [];
                    payloads.product.push(payload);
                }

                return payload;
            },
            'product-click': function (el) {
                const payload = buildProductPayload(el);
                if (!payloadsMatch(payload, el.getAttribute('data-analytics-sent'))) {
                    attachClickListener(el, 'Product Clicked', payload);
                }

                return payload;
            },
            'promotion' : function(el) {
                const payload = buildPromoPayload(el);

                customMetrics.forEach(function (customMetric) {
                    payload[customMetric] = findAttr(el, customMetric);
                });

                customDimensions.forEach(function (customDimension) {
                    payload[customDimension] = findAttr(el, customDimension);
                });

                if (!payloadsMatch(payload, el.getAttribute('data-analytics-sent'))) {
                    payloads.promotion = payloads.promotion || [];
                    payloads.promotion.push(payload);
                }

                return payload;
            },
            'promotion-click' : function(el) {
                const payload = buildPromoPayload(el);
                if (!payloadsMatch(payload, el.getAttribute('data-analytics-sent'))) {
                    attachClickListener(el, 'Promotion Clicked', payload);
                }

                return payload;
            }
        };

        forEach(elements, function (el) {
            const type = el.getAttribute('data-event-type');
            var payload = '';
            if (type in payloadTable && payloadTable[type] instanceof Function) {
                payload = payloadTable[type](el);
            }

            el.setAttribute('data-analytics-sent', buildPayloadString(payload));
        });

        forEach(Object.keys(payloads), function (type) {
            switch (type) {
                case 'list':
                    forEach(Object.keys(payloads[type]), function (list) {
                        const ids = {};
                        const currencyCode = payloads[type][list] && payloads[type][list][0] && payloads[type][list][0].currency ? payloads[type][list][0].currency : '';

                        var elements = payloads[type][list].map(function (payload) {
                            var tempVar = payload.el;
                            delete payload.el;
                            return tempVar;
                        });

                        var listEvent = {
                            list_id: list,
                            category: list,
                            currency: currencyCode,
                            products: payloads[type][list].filter(function (el) {
                                // Quick Search duplicate fix: CP-3950
                                const key = el.product_id + ':' + el.position;
                                if (!ids[key]) {
                                    ids[key] = true;
                                    return true;
                                }
                                return false;
                            })
                        };

                        forEach(elements, function (element) {
                            forEach(customMetrics, function (metric) {
                                listEvent[metric] = findAttr(element, metric);
                            });

                            forEach(customDimensions, function (dimension) {
                                listEvent[dimension] = findAttr(element, dimension);
                            });
                        });

                        analytics.track('Product List Viewed', listEvent);
                        clearMetricsDimensions();
                    });
                    break;
                case 'product':
                    forEach(payloads[type], function (product) {
                        analytics.track('Product Viewed', product);
                        clearMetricsDimensions();
                    });
                    break;
                case 'promotion':
                    forEach(payloads[type], function (promotion) {
                        analytics.track('Promotion Viewed', promotion);
                        clearMetricsDimensions();
                    });
            }
        });
    }

    function buildProductPayload(el) {
        const payload = {
            product_id: findAttr(el, 'data-entity-id'),
            name: findAttr(el, 'data-name'),
            category: findAttr(el, 'data-product-category'),
            brand: findAttr(el, 'data-product-brand'),
            price: findAttr(el, 'data-product-price'),
            sku: findAttr(el, 'data-product-sku'),
            variant: findAttr(el, 'data-product-variant'),
            properties: globalProperties
        };

        const list = findAttr(el, 'data-list-name');
        const position = findAttr(el, 'data-position');

        if (position) {
            payload.position = position;
        }

        if (list) {
            payload.list = list;
        }

        const currencyCode = findAttr(el, 'data-currency-code');
        if (currencyCode) {
            payload.currency = currencyCode;
        }

        return payload;
    }

    function buildPayloadString(payload) {

        return JSON.stringify(
            payload,
            function(k, v) { if (v === undefined) { return null; } return v; }
        );
    }

    function attachClickListener(el, eventType, payload) {
        analytics.trackLink(el, eventType, payload);
    }

    function buildPromoPayload(el) {
        return {
            promotion_id: findAttr(el, 'data-entity-id'),
            name: findAttr(el, 'data-name'),
            creative: findAttr(el, 'data-banner-id'),
            position: findAttr(el, 'data-position'),
            properties: globalProperties
        };
    }
})();
